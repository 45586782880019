<template>
  <div class="lContainer">
    <main class="lMain">
      <div class="lFV">
        <div class="lFV__wrapper">
          <div class="lFV__logo">
            <img src="@/assets/image/fv-logo.svg" alt="ロゴ画像" />
          </div>

          <div class="lFV__headline">
            <h1 class="lFV__h1">salon</h1>
          </div>

          <div class="lFixed__imageText lFixed__imageText--pc">
            日々の忙しさから
            <br />
            解放される
            <br />
            上質な時間を。
          </div>
        </div>
      </div>

      <div class="lFixed">
        <div class="lFixed__wrapper">
          <div class="lFixed__block lFixed__block--image lFixed__block--image01">
            <div class="lFixed__scroller">
              <div class="lFixed__image">
                <picture>
                  <source
                    type="image/webp"
                    media="(min-width: 768px)"
                    srcset="
                      @/assets/image/webp/salon-fv-image-pc.webp    1x,
                      @/assets/image/webp/salon-fv-image-pc@2x.webp 2x
                    "
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset="@/assets/image/salon-fv-image-pc.jpg 1x, @/assets/image/salon-fv-image-pc@2x.jpg 2x"
                  />
                  <source
                    type="image/webp"
                    srcset="@/assets/image/webp/salon-fv-image.webp 1x, @/assets/image/webp/salon-fv-image@2x.webp 2x"
                  />
                  <img
                    src="@/assets/image/salon-fv-image.jpg"
                    srcset="@/assets/image/salon-fv-image@2x.jpg 2x"
                    decoding="async"
                    width="375"
                    height="768"
                    alt=""
                  />
                </picture>

                <div class="lFixed__imageText lFixed__imageText--sp">
                  日々の忙しさから
                  <br />
                  解放される
                  <br />
                  上質な時間を。
                </div>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text lFixed__block--text01">
            <div class="lFixed__scroller">
              <div class="lFixed__left">
                <section class="lSection lSection--first">
                  <p class="lSection__text js-scroll-target">
                    和歌山県南紀白浜にある
                    <br />
                    小さなプライベートサロン polepole。
                    <br />
                    polepoleとはスワヒリ語で
                    <br />
                    ゆっくり、のんびりを意味する言葉。
                    <br />
                    手から伝わるぬくもり
                    <br />
                    手で癒すということを大切にしています。
                  </p>

                  <div class="lSection__h2 js-scroll-target">image</div>

                  <div class="lThumb js-scroll-target">
                    <div class="lThumb__wrapper">
                      <swiper
                        @swiper="setThumbsSwiper"
                        :slidesPerView="5"
                        :slidesPerColumn="3"
                        :spaceBetween="23"
                        :watchSlidesProgress="true"
                        :watchSlidesVisibility="true"
                        :breakpoints="{
                          576: {
                            spaceBetween: 30,
                          },
                        }"
                        class="lThumb__swiper"
                      >
                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image01-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image01-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image01.jpg"
                                srcset="@/assets/image/salon-slide-image01@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image02-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image02-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image02.jpg"
                                srcset="@/assets/image/salon-slide-image02@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image03-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image03-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image03.jpg"
                                srcset="@/assets/image/salon-slide-image03@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image04-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image04-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image04.jpg"
                                srcset="@/assets/image/salon-slide-image04@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image05-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image05-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image05.jpg"
                                srcset="@/assets/image/salon-slide-image05@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image06-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image06-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image06.jpg"
                                srcset="@/assets/image/salon-slide-image06@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image07-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image07-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image07.jpg"
                                srcset="@/assets/image/salon-slide-image07@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image08-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image08-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image08.jpg"
                                srcset="@/assets/image/salon-slide-image08@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image09-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image09-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image09.jpg"
                                srcset="@/assets/image/salon-slide-image09@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image10-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image10-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image10.jpg"
                                srcset="@/assets/image/salon-slide-image10@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image11-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image11-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image11.jpg"
                                srcset="@/assets/image/salon-slide-image11@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image12-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image12-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image12.jpg"
                                srcset="@/assets/image/salon-slide-image12@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image13-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image13-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image13.jpg"
                                srcset="@/assets/image/salon-slide-image13@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image14-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image14-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image14.jpg"
                                srcset="@/assets/image/salon-slide-image14@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>

                        <swiper-slide class="lThumb__item">
                          <div class="lThumb__itemWrapper">
                            <picture>
                              <source
                                type="image/webp"
                                srcset="
                                  @/assets/image/webp/salon-slide-image15-thumb.webp    1x,
                                  @/assets/image/webp/salon-slide-image15-thumb@2x.webp 2x
                                "
                              />
                              <img
                                src="@/assets/image/salon-slide-image15.jpg"
                                srcset="@/assets/image/salon-slide-image15@2x.jpg 2x"
                                decoding="async"
                                width="375"
                                height="768"
                                alt=""
                              />
                            </picture>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>

                  <div class="lSection__image js-scroll-target">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="@/assets/image/webp/top-hand01-pc.webp 1x, @/assets/image/webp/top-hand01-pc@2x.webp 2x"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="@/assets/image/top-hand01-pc.png 1x, @/assets/image/top-hand01-pc@2x.png 2x"
                      />
                      <source
                        type="image/webp"
                        srcset="@/assets/image/webp/top-hand01.webp 1x, @/assets/image/webp/top-hand01@2x.webp 2x"
                      />
                      <img
                        src="@/assets/image/top-hand01.png"
                        srcset="@/assets/image/top-hand01@2x.png 2x"
                        decoding="async"
                        width="291"
                        height="208"
                        alt="手のイラスト その1"
                      />
                    </picture>
                  </div>
                </section>

                <BaseAccess class="lSection--third" />
              </div>
            </div>
          </div>

          <div class="lSlider">
            <div class="lSlider__wrapper">
              <swiper
                class="lSlider__swiper"
                :speed="1600"
                :effect="'fade'"
                :fadeEffect="{
                  crossFade: true,
                }"
                :loop="true"
                :centeredSlides="true"
                :autoplay="{
                  delay: 4000,
                  disableOnInteraction: false,
                }"
                :thumbs="{ swiper: thumbsSwiper }"
              >
                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image01-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image01-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image01-pc.jpg    1x,
                          @/assets/image/salon-slide-image01-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image01.webp    1x,
                          @/assets/image/webp/salon-slide-image01@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image01.jpg"
                        srcset="@/assets/image/salon-slide-image01@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image02-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image02-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image02-pc.jpg    1x,
                          @/assets/image/salon-slide-image02-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image02.webp    1x,
                          @/assets/image/webp/salon-slide-image02@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image02.jpg"
                        srcset="@/assets/image/salon-slide-image02@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image03-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image03-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image03-pc.jpg    1x,
                          @/assets/image/salon-slide-image03-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image03.webp    1x,
                          @/assets/image/webp/salon-slide-image03@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image03.jpg"
                        srcset="@/assets/image/salon-slide-image03@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image04-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image04-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image04-pc.jpg    1x,
                          @/assets/image/salon-slide-image04-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image04.webp    1x,
                          @/assets/image/webp/salon-slide-image04@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image04.jpg"
                        srcset="@/assets/image/salon-slide-image04@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image05-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image05-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image05-pc.jpg    1x,
                          @/assets/image/salon-slide-image05-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image05.webp    1x,
                          @/assets/image/webp/salon-slide-image05@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image05.jpg"
                        srcset="@/assets/image/salon-slide-image05@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image06-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image06-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image06-pc.jpg    1x,
                          @/assets/image/salon-slide-image06-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image06.webp    1x,
                          @/assets/image/webp/salon-slide-image06@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image06.jpg"
                        srcset="@/assets/image/salon-slide-image06@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image07-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image07-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image07-pc.jpg    1x,
                          @/assets/image/salon-slide-image07-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image07.webp    1x,
                          @/assets/image/webp/salon-slide-image07@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image07.jpg"
                        srcset="@/assets/image/salon-slide-image07@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image08-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image08-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image08-pc.jpg    1x,
                          @/assets/image/salon-slide-image08-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image08.webp    1x,
                          @/assets/image/webp/salon-slide-image08@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image08.jpg"
                        srcset="@/assets/image/salon-slide-image08@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image09-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image09-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image09-pc.jpg    1x,
                          @/assets/image/salon-slide-image09-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image09.webp    1x,
                          @/assets/image/webp/salon-slide-image09@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image09.jpg"
                        srcset="@/assets/image/salon-slide-image09@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image10-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image10-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image10-pc.jpg    1x,
                          @/assets/image/salon-slide-image10-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image10.webp    1x,
                          @/assets/image/webp/salon-slide-image10@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image10.jpg"
                        srcset="@/assets/image/salon-slide-image10@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image11-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image11-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image11-pc.jpg    1x,
                          @/assets/image/salon-slide-image11-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image11.webp    1x,
                          @/assets/image/webp/salon-slide-image11@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image11.jpg"
                        srcset="@/assets/image/salon-slide-image11@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image12-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image12-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image12-pc.jpg    1x,
                          @/assets/image/salon-slide-image12-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image12.webp    1x,
                          @/assets/image/webp/salon-slide-image12@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image12.jpg"
                        srcset="@/assets/image/salon-slide-image12@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image13-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image13-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image13-pc.jpg    1x,
                          @/assets/image/salon-slide-image13-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image13.webp    1x,
                          @/assets/image/webp/salon-slide-image13@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image13.jpg"
                        srcset="@/assets/image/salon-slide-image13@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image14-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image14-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image14-pc.jpg    1x,
                          @/assets/image/salon-slide-image14-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image14.webp    1x,
                          @/assets/image/webp/salon-slide-image14@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image14.jpg"
                        srcset="@/assets/image/salon-slide-image14@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>

                <swiper-slide class="lSlider__item">
                  <div class="lSlider__itemWrapper">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/webp/salon-slide-image15-pc.webp    1x,
                          @/assets/image/webp/salon-slide-image15-pc@2x.webp 2x
                        "
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="
                          @/assets/image/salon-slide-image15-pc.jpg    1x,
                          @/assets/image/salon-slide-image15-pc@2x.jpg 2x
                        "
                      />
                      <source
                        type="image/webp"
                        srcset="
                          @/assets/image/webp/salon-slide-image15.webp    1x,
                          @/assets/image/webp/salon-slide-image15@2x.webp 2x
                        "
                      />
                      <img
                        src="@/assets/image/salon-slide-image15.jpg"
                        srcset="@/assets/image/salon-slide-image15@2x.jpg 2x"
                        decoding="async"
                        width="375"
                        height="768"
                        alt=""
                      />
                    </picture>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text lFixed__block--text02">
            <div class="lFixed__scroller">
              <BaseAccess class="lSection--second" />
            </div>
          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import BaseAccess from "@/components/BaseAccess.vue";
import Foundation from "@/libs/foundation";

import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import SwiperCore, { Autoplay, EffectFade, Thumbs } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Autoplay, EffectFade, Thumbs]);

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Salon",
  components: {
    Footer,
    BaseAccess,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    scrollAnime() {
      this.targetArry = gsap.utils.toArray(".js-scroll-target").map((target) => {
        return gsap.from(target, {
          scrollTrigger: {
            trigger: target,
            start: "top 90%",
          },
          opacity: 0,
          scale: 0.95,
          y: 30,
          duration: 2,
          ease: "power4.out",
        });
      });
    },
  },
  mounted() {
    if (document.querySelector(".js-load")) {
      document.querySelector(".js-load").remove();
    }

    Foundation();
    this.scrollAnime();
    ScrollTrigger.refresh();
  },
  beforeUnmount() {
    this.targetArry.forEach((target) => {
      target.scrollTrigger.kill();
      target.kill();
    });
  },
};
</script>

<style lang="scss" scoped>
.lFV {
  padding: 6.9rem 0 4.6rem;
  background-color: #fff;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logo {
    width: 170px;
    height: auto;
  }

  &__headline {
    margin-top: 10rem;
  }

  &__h1 {
    font-family: var(--lato);
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0.1em;
  }
}

.lFixed {
  &__wrapper {
    width: 100%;
  }

  &__block {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    &--text {
      position: relative;
      background-color: #fff;
      z-index: 111;

      & .lFixed__scroller {
        height: auto;
      }
    }

    &--image {
      z-index: -1;
    }
  }

  &__scroller {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    & img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__imageText {
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: 1;
    font-size: 1.4rem;
    letter-spacing: 0.3em;
    line-height: 2.4;
    font-weight: 400;
    color: #fff;
    writing-mode: vertical-rl;
    transform: translateX(-50%);

    &--pc {
      display: none;
    }
  }
}

.lSection {
  &__text {
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    font-weight: 400;
    line-height: 2.3;
  }

  &__modal {
    max-width: 375px;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin: 5rem auto 0;

    & > button {
      position: relative;
      display: block;
      width: 100%;
      padding: 0 0 1rem;
      text-align: left;

      & .icon {
        position: absolute;
        top: calc(50% - 0.7rem);
        right: 0;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #000;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
      }
    }

    & + .cAccess__link {
      margin-top: 3rem;
    }
  }

  &--first {
    padding: 7.7rem 42px 3rem;

    & .lSection {
      &__h2 {
        font-family: var(--lato);
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: 4rem;
      }

      &__image {
        display: none;
      }
    }
  }

  &--second {
    padding: 7rem 42px 7rem;
  }

  &--third {
    display: none;
  }
}

.lThumb {
  max-width: 375px;
  height: 172px;
  margin: 3rem auto 0;

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__swiper {
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    width: 40px;
    height: 42px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: #000 solid 2px;
      opacity: 0;
      transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.swiper-slide-thumb-active {
      &::after {
        opacity: 1;
      }
    }
  }

  &__itemWrapper {
    width: 100%;
    height: 100%;
  }
}

.lSlider {
  position: sticky;
  top: 0;
  background-color: #fff;

  &__item {
    width: 100%;

    & img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@include g.responsive(sm) {
  .lFV {
    padding: 3.5rem 0 11rem;
  }

  .lFixed {
    position: relative;
    z-index: 1;

    &__block {
      &--image02 {
        display: none;
      }
    }

    &__left {
      width: 50%;
      padding-bottom: 10rem;
    }

    &__image {
      display: block;
    }

    &__imageText {
      top: 158px;
      left: g.px-vw(422, 1440);
      color: #000;
      transform: translateX(0);

      &--sp {
        display: none;
      }

      &--pc {
        display: block;
      }
    }
  }

  .lSection {
    &--first {
      max-width: 383px;
      margin: 0 auto;
      padding: 10rem 0 0;

      & .lSection {
        &__image {
          display: block;
          width: 360px;
          margin: 4.5rem 0 0 6rem;
        }
      }
    }

    &--second {
      display: none;
    }

    &--third {
      display: block;
      margin-top: 9rem;
    }
  }

  .lThumb {
    max-width: 320px;
    height: 186px;
    margin: 3rem 0 0;
  }

  .lSlider {
    position: absolute;
    top: 100vh;
    right: 0;
    display: block;
    width: 50%;
    height: calc(100% - 100vh);
    background-color: #fff;
    z-index: 1000;

    &__wrapper {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
    }

    &__swiper {
      height: 100%;
    }

    &__item {
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.swiper-slide-active,
      &.swiper-slide-duplicate-active,
      &.swiper-slide-prev {
        & img {
          animation: zoomUp 10s linear 0s 1 normal both;
        }
      }
    }

    &__itemWrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .lFooter {
    margin-top: -1rem;
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
